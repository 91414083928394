import React, { useState } from "react"
import Img from "gatsby-image"
import styles from "../styles/history.module.css"
import Slider from "react-slick"
import FlechaSelected from "../images/logos/flechaSelected.svg"
import BlueCircle from "../images/logos/blueCircle.svg"
import { useMediaQuery } from "react-responsive"
import { useScrollData } from "scroll-data-hook"

const ingredients = [
  {
    title: "CEBADA DEL SUR DE BS. AS.",
    text: `Es el ingrediente principal, se encarga de darle los elementos necesarios para su sabor, color, cuerpo y espuma, característicos de la Quilmes clásica. Somos los únicos en el país en crear nuestras propias variedades de cebada. Seleccionamos y cultivamos nuestras semillas bajo un riguroso control, asegurando la calidad desde el campo hasta tu mesa. `,
    img: "cebada",
    alt: "campo de cebada",
  },
  {
    title: "LÚPULO PATAGÓNICO",
    text: `El condimento de la cerveza, le da sus toques de aroma, sabor y amargor justo. Quilmes fue el promotor de la producción nacional de lúpulo en la Patagonia Argentina, el único lugar de Sudamérica donde puede cultivarse, ya que allí se combinan clima frío, suelo fértil y horas de sol necesarias. Elaboramos con cuatro variedades de lúpulo: Nugget, Cascade, Victoria y Mapuche`,
    img: "lupulo",
    alt: "lúpulo patagónico",
  },
  {
    title: "MAÍZ DEL NORTE DE ARGENTINA",
    text: `En Quilmes seleccionamos el maíz que sembramos, compramos semillas y las enviamos a productores del norte argentino, para que nos devuelvan el maíz, fundamental en la elaboración de nuestra cerveza. Es el encargado de balancear nuestra cerveza y de darle su cremosidad, refrescancia y tomabilidad características.`,
    img: "maiz",
    alt: "campo de maíz",
  },
  {
    title: "LEVADURA",
    text: `Utilizamos la variedad tipo Lager, responsable de generar el alcohol los aromas y la carbonatación de forma natural. Nuestra levadura es controlada y purificada para mantener el perfil de nuestra cerveza de forma invariable.`,
    img: "levadura",
    alt: "recipiente con levadura",
  },
  {
    title: "SIN CONSERVANTES",
    text: `Este es el "no ingrediente" de nuestra cerveza, porque Quilmes no lleva ningún tipo de conservante ni aditivo. De esta manera, elaboramos una cerveza de calidad y 100% natural.`,
    img: "cerveza",
    alt: "cerveza con espuma en vaso",
  },
]

function NuestrosIngredientes({ data }) {
  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  const { position } = useScrollData({})
  const [currentDot, setCurrentDot] = useState(0)
  let settings
  mobile
    ? (settings = {
        infinite: false,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSLide: true,
        arrows: false,
        swipe: true,
        touchMove: true,
        dots: true,
        centerMode: false,
        variableWidth: false,
        className: styles.slideIngredients,
        afterChange: index => {
          setCurrentDot(index)
        },
        appendDots: dots => (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              bottom: "0%",
            }}
          >
            <ul className={styles.customUl} style={{ margin: "0px" }}>
              {" "}
              {dots}{" "}
            </ul>
          </div>
        ),
        customPaging: i =>
          currentDot === i ? (
            <div id={styles.decoCircleActive}>
              <div id={styles.decoInnerCircle}>
                <div id={styles.decoInnerDot}></div>
              </div>
              {i === 4 ? null : <hr id={styles.lineaBlueCircle}></hr>}
            </div>
          ) : (
            <div id={styles.decoCircle}>
              <div id={styles.decoInnerCircle}>
                <div id={styles.decoInnerDot}></div>
              </div>
              {i === 4 ? null : <hr id={styles.lineaBlueCircle}></hr>}
            </div>
          ),
      })
    : (settings = settings = {
        infinite: false,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSLide: true,
        arrows: false,
        swipe: true,
        touchMove: true,
        dots: true,
        centerMode: false,
        afterChange: index => {
          setCurrentDot(index)
        },
        appendDots: dots => (
          <div
            style={{
              backgroundColor: "transparent",
              position: "absolute",
              bottom: "-5%",
            }}
          >
            <ul className={styles.customUl} style={{ margin: "0px" }}>
              {" "}
              {dots}{" "}
            </ul>
          </div>
        ),
        customPaging: i =>
          currentDot === i ? (
            <div id={styles.decoCircleActive}>
              <div id={styles.decoInnerCircle}>
                <div id={styles.decoInnerDot}></div>
              </div>
              {i === 4 ? null : <hr id={styles.lineaBlueCircle}></hr>}
            </div>
          ) : (
            <div id={styles.decoCircle}>
              <div id={styles.decoInnerCircle}>
                <div id={styles.decoInnerDot}></div>
              </div>
              {i === 4 ? null : <hr id={styles.lineaBlueCircle}></hr>}
            </div>
          ),
      })
  return (
    <>
      <section className={styles.sectionThree}>
        <h1 id={styles.ourIngredients}>NUESTROS INGREDIENTES</h1>
        <Slider {...settings}>
          {ingredients.map((item, index) => {
            return (
              <div key={item.title} className={`${styles.slide}`}>
                <div className={styles.slideContent}>
                  <div className={styles.slideText}>
                    <div id={styles.flechaTitle}>
                      <img alt="" src={FlechaSelected} />
                      <h1>{item.title}</h1>
                    </div>
                    <p>{item.text}</p>
                  </div>  
                  <Img
                    className={styles.slideImg}
                    fluid={data[item.img].childImageSharp.fluid}
                  />
                  {mobile ? (
                    index === 0 ? null : (
                      <hr id={styles.lineaSlider}></hr>
                    )
                  ) : index === 4 ? null : (
                    <hr id={styles.lineaSlider}></hr>
                  )}
                </div>
              </div>
            )
          })}
        </Slider>
      </section>
    </>
  )
}

export default NuestrosIngredientes
