import React, { useEffect } from "react"
import Layout from "../components/layout"
import styles from "../styles/history.module.css"
import FondoQ from "../images/fondoQ.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import HistoryTop from "../components/historyTop"
import NuestroComienzo from "../components/nuestroComienzo"
import NuestrosIngredientes from "../components/nuestrosIngredientes"
import ComoElaboramos from "../components/comoElaboramos"
import NuestroMaestro from "../components/nuestroMaestro"
import { navigate } from "gatsby"

function History({ data }) {

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    // console.log(local,session)
    if (!session && !local) navigate("/agegate",{state: {newPath : "/history"}})
  },[]) 
 
  useEffect(() => {
    window.dataLayer.push({
      brand: "Quilmes",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Nuestra Historia",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.quilmes.com.ar/history",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])

  return (
    <>
      <Layout>
        <img
          alt="logo de 130 años de cerveza Quilmes"
          className={styles.fondoQ}
          src={FondoQ}
        />
        <HistoryTop data={data}></HistoryTop>
        <NuestroComienzo></NuestroComienzo>
        <NuestrosIngredientes data={data}></NuestrosIngredientes>
        <ComoElaboramos data={data}></ComoElaboramos>
        <NuestroMaestro data={data}></NuestroMaestro>
      </Layout>
    </>
  )
}

export default History
export const query = graphql`
  query {
    flagImage: file(relativePath: { eq: "flag.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cebada: file(relativePath: { eq: "cebada.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lupulo: file(relativePath: { eq: "lupulo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    maiz: file(relativePath: { eq: "maiz.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    levadura: file(relativePath: { eq: "levadura.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cerveza: file(relativePath: { eq: "cerveza.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    quilmes: file(relativePath: { eq: "quilmes.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    luis: file(relativePath: { eq: "luisDimotta.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cervezahielo: file(relativePath: { eq: "cervezahielo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vasobirra: file(relativePath: { eq: "vasoBirra.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    canillas: file(relativePath: { eq: "canillas.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smokyroom: file(relativePath: { eq: "mesasmoky.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flag: file(relativePath: { eq: "flag.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
