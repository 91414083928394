import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Line from "../images/linea.svg"
import styles from "../styles/history.module.css"
/* import YouTube from "react-youtube" */
import ReactPlayer from "react-player"
import { useScrollData } from "scroll-data-hook"

function NuestroMaestro({ data }) {
  const { position } = useScrollData({})

  return (
    <>
      <section id={styles.sectionFive}>
        <div className={styles.sectionFiveContainer}>
          <div className={styles.positionImg}>
            <Img
              className={styles.luisDimotta}
              fluid={data.luis.childImageSharp.fluid}
            />
            <Img
              className={styles.cervezaHielo}
              fluid={data.cervezahielo.childImageSharp.fluid}
            />
          </div>
          <div className={`${styles.dimottaContainer}`}>
            <h1 className={`${styles.slideColTextBottom}`}>LUIS DIMOTTA</h1>
            <h2 className={`${styles.slideColTextBottom}`}>NUESTRO MAESTRO</h2>
            <img alt="" src={Line} />
            <p className={`${styles.slideColTextBottom}`}>
              Luis Dimotta, nuestro maestro cervecero, es el encargado de
              elaborar la cerveza más famosa de Argentina. La pasión y el tiempo
              dedicado a nuestra cerveza se nota en las recetas originales de
              todas las variedades a lo largo y ancho del país. Pero este
              importante trabajo no es para cualquiera, para llegar a ser
              maestro cervecero de Quilmes es necesario haber hecho cerveza
              durante al menos 10 años. A todo eso, nosotros le llamamos cariño.{" "}
              <strong>¡Bravo Luis!</strong>
            </p>
            <div>
              <a
                target="_blank"
                href="https://ar.linkedin.com/in/luis-alberto-dimotta-0261b047"
              >
                <button
                  className={styles.buttonLuis}
                  onClick={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Content",
                      event_action: "Nuestro maestro",
                      event_label: `Conocé a Luiss`,
                      interaction: true,
                      component_name: "boton_conoce_luis",
                      element_text: "Conocé a Luis",
                    })
                  }}
                >
                  CONOCÉ A LUIS
                </button>
              </a>
              <a
                target="_blank"
                href="mailto:ludimott@quilmes.com.ar"
                onClick={() => {
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Content",
                    event_action: "Nuestro Maestro",
                    event_label: `Contactalo`,
                    interaction: true,
                    component_name: "boton_contactalo",
                    element_text: "Contactalo",
                  })
                }}
              >
                <button className={styles.buttonContactLuis}>CONTACTALO</button>
              </a>
            </div>
          </div>
          <Img
            className={styles.canillas}
            fluid={data.canillas.childImageSharp.fluid}
          />

          <Img
            className={styles.vasoBirra}
            fluid={data.vasobirra.childImageSharp.fluid}
          />
        </div>

        <div className={styles.youtubeContainer}>
          <h1>
            SI TE QUEDASTE CON LAS GANAS DE MÁS,
            <br />
            TE DEJAMOS NUESTRAS PUBLICIDADES MÁS ICÓNICAS.
          </h1>

          <ReactPlayer
            className={styles.youtubePlayer}
            url="https://youtube.com/playlist?list=PLaD8RQ7SMhSIcQeTtuFQOduCo2PgYtxbv"
            width="50%"
            height="65%"
            controls
            volume="0.8"
            onStart={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Video",
                event_action: `Play`,
                event_label: `Publicidad Historicas`,
                interaction: true,
              })
            }}
            onEnded={() => {
              window.dataLayer.push({
                event: "GAEvent",
                event_category: "Video",
                event_action: `Completed`,
                event_label: `Publicidad Historicas`,
                interaction: true,
              })
            }}
          />
        </div>
      </section>
    </>
  )
}

export default NuestroMaestro
