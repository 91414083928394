import React from "react"
import Img from "gatsby-image"
import Elaboracion from "../images/elaboracion.svg"
import SimpleSlider from "../components/process"
import styles from "../styles/history.module.css"
import { useMediaQuery } from "react-responsive"
import { useScrollData } from "scroll-data-hook"
import lineaElaboracion from "../images/comoElaboramosDeco.svg"

const elaboracion = [
  {
    title: "Campo",
    text: "",
    margin: "0",
  },
  {
    title: "Remojo, Germinación y Secado",
    text:
      "Luego de obtener los granos de cebada, estos son llevados a nuestras malterías donde comienza el proceso.",
    margin: "0",
  },
  {
    title: "Molienda y Maceración",
    text:
      "El grano de cebada es partido para aprovechar todos sus nutrientes. Se lo mezcla con agua para obtener el mosto, un líquido espeso y de sabor dulce. Luego de agregar el agua, agregamos el maíz al proceso.",
    margin: "0",
  },
  {
    title: "Cocimiento y enfriado",
    text:
      "Aqui hervimos el mosto a más de 100° y agregamos otro ingrediente: el lúpulo, luego creamos las condiciones necesarias para comenzar la fermentación y maduración.",
    margin: "0",
  },
  {
    title: "Fermentación y Maduración",
    text:
      "La fermentación es un proceso que dura 14 días en el agregamos la levadura tipo lager, que es la encargada de generar la carbonatación y el alcohol natural en el mosto. Luego se deja al mosto estabilizarse, madurar y reposar.",
    margin: "0",
  },
  {
    title: "Filtrado y embotellado",
    text:
      "El filtrado nos permite obtener el color brillante y dorado de nuestra Quilmes, para luego pasar a la etapa en la cual envasamos y pasteurizamos nuestra cerveza. Este proceso es el que nos permite optimizar su conservación.",
    margin: "0",
  },
  {
    title: "Salud!",
    text: "",
    margin: "0",
  },
]

function ComoElaboramos({ data }) {
  const {
    scrolling,
    time,
    speed,
    direction,
    position,
    relativeDistance,
    totalDistance,
  } = useScrollData({
    onScrollStart: () => {},
  })

  const mobile = useMediaQuery({ query: "(max-width: 485px)" })
  return (
    <>
      <section className={styles.sectionFour}>
        {!mobile ? (
          <>
            {/* <Img
              className={styles.quilmes}
              fluid={data.quilmes.childImageSharp.fluid}
            /> */}
            <h1>CÓMO ELABORAMOS NUESTRA CERVEZA</h1>
            {/*       */}
            <div className={styles.sectionFourTextContainer}>
              <div >
                <img
                  alt="logo proceso de elaboración de cerveza Quilmes"
                  id={styles.elaboracion}
                  src={Elaboracion}
                />
              </div>
              <div id={styles.textDot}>
                <img src={lineaElaboracion} />
              </div>
              <div style={{height: "100%", flexDirection: "column", marginLeft: "15%", width: "100%"}}>
              {elaboracion.map((step, index) => {
                return (
                  <div style={{height: "14%",width: "100%"}}>
                    <div
                      key={step.index}
                      style={{ marginBottom: `${step.margin}` }}
                      className={`${styles.sectionFourText}`}
                    >

                      <h5
                        className={
                          position.y > 1800
                            ? `${styles.stepTitleAnimation}`
                            : null
                        }
                      >
                        {step.title.toUpperCase()}
                      </h5>
                      <p
                        className={
                          position.y > 1800
                            ? `${styles.sectionFourTextAnimation}`
                            : null
                        }
                      >
                        {step.text}
                      </p>
                    </div>
                  </div>
                )
              })}
              </div>
            </div>{" "}
          </>
        ) : (
          <SimpleSlider></SimpleSlider>
        )}
      </section>
    </>
  )
}

export default ComoElaboramos
